.img-full {
  width: 100%;
}

.pagina-principal {
  /* max-width: 1200px; */
  margin: 0 auto;
  color: var(--carpenter-core-color-light-on-baseplate);
}

/* Sección principal */
.seccion-principal {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.contenedor-principal img {
  width: 142px;
}

.contenedor-principal h1 {
  margin: 20px 0;
  font-size: 28px;
  font-weight: 900;
  line-height: 36px;
}

.contenedor-principal p {
  margin: 20px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.contenedor-imagen .imagen-principal-desktop {
  display: none;
}

.contenedor-salones p {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.lista-salones ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.lista-salones img {
  width: 55px;
}

/* Sección beneficios */
.seccion-beneficios {
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  /* background: #f3f7f9; */
}

.contenedor-beneficios h2 {
  margin: 40px 0;
  font-weight: 900;
  font-size: 25px;
}

.beneficio-item {
  margin: 20px 0;
  display: flex;
  gap: 1rem;
}

.beneficio-item p {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.icon-beneficios {
  width: 28px;
}

.beneficio-item p {
  margin: 0;
  text-align: left;
}

.contenedor-imagen img {
  margin: 20px 0;
}

.contenedor-imagen .imagen-pantallas-desktop {
  display: none;
}

/* Sección ayudamos */
.seccion-ayudamos {
  display: grid;
  grid-template-areas:
    "seccionInformacion"
    "seccionImagen";
  text-align: center;
}

.seccion-ayudamos .contenedor-imagen {
  grid-area: seccionImagen;
}

.seccion-ayudamos .contenedor-ayudamos {
  padding: 0 1.5rem;
  grid-area: seccionInformacion;
}

.contenedor-ayudamos h2 {
  margin: 40px 0;
  font-weight: 900;
  font-size: 25px;
}

.contenedor-ayudamos h3 {
  margin: 20px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.contenedor-ayudamos p {
  margin: 20px 0;
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

/* Sección info */
.seccion-info {
  padding: 3.5rem 1.5rem;
  margin-top: 20px;
  text-align: center;
  background: var(--carpenter-core-color-light-accent-minor);
}

.seccion-info__parrafo {
  margin: 0 0 20px 0;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
}

.seccion-info__parrafo span {
  font-weight: 900;
}

/* Sección preguntas */
.seccion-preguntas {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.contenedor-preguntas h2 {
  margin: 40px 0;
  text-align: center;
  font-weight: 900;
  font-size: 25px;
}

.pregunta h3 {
  margin: 20px 0;
  font-weight: 700;
  font-size: 18px;
}

.pregunta p {
  margin: 20px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.contenedor-imagen .imagen-mano{
  display: none;
}

/* Sección centros */
.seccion-centros {
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
}

.container-centros__parrafo {
  margin: 20px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.container-centros__parrafo span {
  font-weight: 900;
}

.contenedor-imagen .imagen-laptop-desktop {
  display: none;
}

/* Sección quote */
.seccion-quote {
  padding: 2.5rem 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  text-align: justify;
}

.contenedor-quote {
  position: relative;
  padding: 1.5rem 1rem;
  margin: 1.5rem 0;
  border-radius: 6px;
  background: var(--carpenter-core-color-light-surface);
  box-shadow: 0px 0.812222px 4.06111px rgba(148, 148, 148, 0.25);
}

.contenedor-quote p {
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.contenedor-quote .contenedor-quote__team {
  font-weight: 700;
}

.icon-quote {
  position: absolute;
  top: 0%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 35px;
}

/* Footer */
.footer-landing {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  background: var(--carpenter-core-color-light-primary);
  /* text-align: center; */
}

.redes-sociales {
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
}

.iconos-redes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.iconos-redes a {
  justify-self: center;
  align-self: end;
}

.copyright {
  padding: 0 24px;
}

.copyright p {
  margin: 12px 0;
  font-weight: 400;
  font-size: 8px;
  color: var(--carpenter-core-color-light-on-primary);
}

/* Media queries */
@media (min-width: 768px) {
  /* Sección principal */
  .seccion-principal {
    padding: 20px 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr auto;
    text-align: left;
    align-items: center;
    gap: 40px;
  }

  .seccion-principal .contenedor-principal {
    padding-left: 40px;
  }

  .contenedor-imagen .imagen-principal {
    display: none;
  }

  .contenedor-imagen .imagen-principal-desktop {
    display: block;
  }

  .contenedor-salones {
    grid-column: 1/3;
    text-align: center;
    padding: 0 40px;
  }

  .lista-salones ul {
    max-width: 650px;
    margin: 0 auto;
    padding: 20px;
    justify-content: space-evenly;
  }

  /* Sección beneficios */
  .seccion-beneficios {
    padding: 20px 0;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: left;
    gap: 40px;
  }

  .seccion-beneficios .contenedor-beneficios {
    padding-left: 40px;
  }

  .beneficio-item {
    margin: 20px 0;
  }

  .contenedor-imagen .imagen-pantallas {
    display: none;
  }

  .contenedor-imagen .imagen-pantallas-desktop {
    display: block;
  }

  /* Sección ayudamos */
  .seccion-ayudamos {
    padding: 20px 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "seccionImagen seccionInformacion";
    align-items: center;
    gap: 40px;
    text-align: left;
  }

  .seccion-ayudamos .contenedor-ayudamos {
    padding-right: 40px;
  }

  /* Sección info */
  .seccion-info .seccion-info__parrafo {
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  /* Sección preguntas */
  .seccion-preguntas {
    padding: 20px 40px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .contenedor-preguntas h2 {
    text-align: left;
  }

  .seccion-preguntas .contenedor-imagen {
    display: flex;
    justify-content: center;
  }

  .contenedor-imagen .imagen-mano{
    display: block;
    max-width: 400px;
  }

  /* Sección centros */
  .seccion-centros {
    padding: 20px 0;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    text-align: left;
    gap: 40px;
  }

  .contenedor-imagen .imagen-laptop {
    display: none;
  }

  .contenedor-imagen .imagen-laptop-desktop {
    display: block;
    max-width: 650px;
  }

  .seccion-centros .container-centros {
    padding-right: 40px;
  }

  /* Sección quote */
  .seccion-quote {
    padding: 40px;
  }

  .contenedor-quote {
    padding: 20px;
  }

  /* Footer */
  .redes-sociales {
    padding: 20px 40px;
  }

  .copyright {
    padding: 0 40px;
  }
}

@media (min-width: 1024px) {
  .seccion-principal .contenedor-principal {
    padding-left: 100px;
  }

  .contenedor-principal img {
    width: 160px;
  }

  .contenedor-principal h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .contenedor-principal p {
    font-size: 20px;
    line-height: 30px;
  }

  .contenedor-salones {
    grid-column: 1/3;
    text-align: center;
  }

  .contenedor-salones p {
    padding: 10px;
    font-size: 15px;
  }

  .lista-salones ul {
    max-width: 1400px;
  }

  .lista-salones .salon-imagen {
    width: 100px;
  }

  /* Sección beneficios */
  .seccion-beneficios .contenedor-beneficios {
    padding-left: 100px;
  }

  .contenedor-beneficios h2 {
    font-size: 30px;
  }

  .beneficio-item p {
    font-size: 15px;
    line-height: 25px;
  }

  .icon-beneficios {
    width: 40px;
  }

  .seccion-beneficios .contenedor-imagen {
    padding: 20px 0;
  }

  /* Seccion ayudamos */
  .seccion-ayudamos .contenedor-ayudamos {
    padding-right: 100px;
  }

  .contenedor-ayudamos h2 {
    font-size: 30px;
  }

  .contenedor-ayudamos h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .contenedor-ayudamos p {
    font-size: 15px;
    line-height: 25px;
  }

  /* Sección info */
  .seccion-info .seccion-info__parrafo {
    font-size: 25px;
    line-height: 35px;
  }

  .seccion-preguntas {
    padding: 20px 100px;
  }

  .contenedor-preguntas h2 {
    font-size: 30px;
  }

  .pregunta h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .pregunta p {
    font-size: 15px;
    line-height: 25px;
  }

  /* Sección centros */
  .seccion-centros {
    padding: 20px 0;
  }

  .seccion-centros .container-centros {
    padding-right: 100px;
  }

  .container-centros .container-centros__parrafo {
    font-size: 25px;
    line-height: 35px;
  }

  /* Sección quote */
  .seccion-quote {
    padding: 20px 100px;
    margin-top: 40px;
  }

  .contenedor-quote {
    padding: 40px;
  }

  .contenedor-quote p {
    font-size: 15px;
    line-height: 25px;
  }

  .icon-quote {
    width: 50px;
  }

  /* Footer */
  .logo-viutify-footer {
    width: 180px;
  }

  .redes-sociales {
    padding: 20px 100px;
  }

  .iconos-redes {
    gap: 40px;
  }

  .iconos-redes .icon-whatsapp {
    width: 25px;
  }

  .iconos-redes .icon-facebook {
    width: 12px;
  }

  .iconos-redes .icon-instagram {
    width: 22px;
  }

  .copyright {
    padding: 0 100px;
  }

  .copyright p {
    margin: 20px 0;
    font-size: 12px;
  }
}

@media (min-width: 1440px) {
  /* Sección principal */
  .contenedor-principal img {
    width: 200px;
  }

  .contenedor-principal h1 {
    margin: 40px 0;
    font-size: 60px;
    line-height: 70px;
  }

  .contenedor-principal p {
    margin: 40px 0;
    font-size: 30px;
    line-height: 40px;
  }

  .contenedor-salones p {
    padding: 20px;
    font-size: 25px;
  }

  .lista-salones ul {
    padding: 40px 0;
  }

  .lista-salones .salon-imagen {
    width: 150px;
  }

  /* Sección beneficios */
  .contenedor-beneficios h2 {
    margin: 60px 0;
    font-size: 50px;
  }

  .beneficio-item {
    gap: 2rem;
    margin: 40px 0;
  }

  .beneficio-item p {
    font-size: 25px;
    line-height: 35px;
  }

  .icon-beneficios {
    width: 60px;
  }

  .seccion-beneficios .contenedor-imagen {
    padding: 40px 0;
  }

  /* Sección ayudamos */
  .contenedor-ayudamos h2 {
    margin: 60px 0;
    font-size: 50px;
  }

  .contenedor-ayudamos h3 {
    font-size: 30px;
    line-height: 40px;
    margin: 40px 0;
  }

  .contenedor-ayudamos p {
    font-size: 25px;
    line-height: 35px;
    margin: 40px 0;
  }

  /* Sección info */
  .seccion-info {
    padding: 4rem 0;
    margin: 40px 0;
  }

  .seccion-info .seccion-info__parrafo {
    max-width: 700px;
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 40px;
  }

  /* Sección preguntas */
  .seccion-preguntas {
    padding: 40px 0 40px 100px;
  }

  .contenedor-preguntas h2 {
    margin: 60px 0;
    font-size: 50px;
  }

  .pregunta h3 {
    margin: 40px 0;
    font-size: 30px;
    line-height: 40px;
  }

  .pregunta p {
    margin: 40px 0;
    font-size: 20px;
    line-height: 30px;
  }

  .contenedor-imagen .imagen-mano{
    max-width: 500px;
  }

  /* Sección centros */
  .seccion-centros {
    padding: 40px 0;
  }

  .contenedor-imagen .imagen-laptop-desktop {
    max-width: initial;
  }

  .container-centros .container-centros__parrafo {
    margin: 40px 0;
    font-size: 45px;
    line-height: 60px;
  }

  /* Sección quote */
  .seccion-quote {
    padding: 40px 100px;
  }

  .contenedor-quote {
    padding: 60px;
  }

  .contenedor-quote p {
    margin: 40px 0;
    font-size: 30px;
    line-height: 40px;
  }

  .icon-quote {
    width: 85px;
  }

  /* Footer */
  .redes-sociales {
    padding: 40px 100px;
  }

  .logo-viutify-footer {
    width: 250px;
  }

  .iconos-redes .icon-whatsapp {
    width: 40px;
  }

  .iconos-redes .icon-facebook {
    width: 18px;
  }

  .iconos-redes .icon-instagram {
    width: 32px;
  }

  .copyright p {
    font-size: 17px;
  }
}
