/**
 * Do not edit directly
 * Generated on Fri, 10 Mar 2023 16:27:16 GMT
 */

:root {
  --carpenter-core-color-light-baseplate: #fefbff;
  --carpenter-core-color-light-on-baseplate: #1b1b1f;
  --carpenter-core-color-light-primary: #5d36f1;
  --carpenter-core-color-light-on-primary: #ffffff;
  --carpenter-core-color-light-primary-minor: #e5deff;
  --carpenter-core-color-light-on-primary-minor: #1a0063;
  --carpenter-core-color-light-secondary: #9100dd;
  --carpenter-core-color-light-on-secondary: #ffffff;
  --carpenter-core-color-light-secondary-minor: #f3daff;
  --carpenter-core-color-light-on-secondary-minor: #2f004c;
  --carpenter-core-color-light-accent: #006686;
  --carpenter-core-color-light-on-accent: #ffffff;
  --carpenter-core-color-light-accent-minor: #c0e8ff;
  --carpenter-core-color-light-on-accent-minor: #001e2b;
  --carpenter-core-color-light-outline: #77767a;
  --carpenter-core-color-light-outline-variant: #c9c5ca;
  --carpenter-core-color-light-bad: #ba1a1a;
  --carpenter-core-color-light-on-bad: #ffffff;
  --carpenter-core-color-light-bad-minor: #ffdad6;
  --carpenter-core-color-light-on-bad-minor: #410002;
  --carpenter-core-color-light-good: #006e2a;
  --carpenter-core-color-light-on-good: #ffffff;
  --carpenter-core-color-light-good-minor: #ceeacb;
  --carpenter-core-color-light-on-good-minor: #002107;
  --carpenter-core-color-light-beware: #6d5e00;
  --carpenter-core-color-light-on-beware: #ffffff;
  --carpenter-core-color-light-beware-minor: #f0e2af;
  --carpenter-core-color-light-on-beware-minor: #1c1d00;
  --carpenter-core-color-light-absent: #ffffff;
  --carpenter-core-color-light-on-absent: #5d5e67;
  --carpenter-core-color-light-absent-minor: #e2e1ec;
  --carpenter-core-color-light-scrim: #1b1b1e99;
  --carpenter-core-color-light-surface: #fefbff;
  --carpenter-core-color-light-on-surface: #1b1b1e;
  --carpenter-core-color-light-surface-minor: #e6e0ec;
  --carpenter-core-color-light-on-surface-minor: #48454e;
  --carpenter-core-color-light-inverse-surface: #303033;
  --carpenter-core-color-light-on-inverse-surface: #f3f0f4;
  --carpenter-core-color-light-inverse-primary: #c9bfff;
  --carpenter-core-color-light-superface-tint: #5d36f1;
  --carpenter-core-color-dark-baseplate: #1b1b1e;
  --carpenter-core-color-dark-on-baseplate: #f4eff4;
  --carpenter-core-color-dark-primary: #c9bfff;
  --carpenter-core-color-dark-on-primary: #2e009c;
  --carpenter-core-color-dark-primary-minor: #4400d9;
  --carpenter-core-color-dark-on-primary-minor: #e5deff;
  --carpenter-core-color-dark-secondary: #e3b5ff;
  --carpenter-core-color-dark-on-secondary: #4e0079;
  --carpenter-core-color-dark-secondary-minor: #6e00aa;
  --carpenter-core-color-dark-on-secondary-minor: #f3daff;
  --carpenter-core-color-dark-accent: #71d2ff;
  --carpenter-core-color-dark-accent-minor: #004d66;
  --carpenter-core-color-dark-on-accent: #003547;
  --carpenter-core-color-dark-on-accent-minor: #c0e8ff;
  --carpenter-core-color-dark-outline: #919094;
  --carpenter-core-color-dark-outline-variant: #48464a;
  --carpenter-core-color-dark-bad: #ffb4ab;
  --carpenter-core-color-dark-bad-minor: #93000a;
  --carpenter-core-color-dark-on-bad: #690005;
  --carpenter-core-color-dark-on-bad-minor: #ffdad6;
  --carpenter-core-color-dark-good: #7edb89;
  --carpenter-core-color-dark-on-good: #003912;
  --carpenter-core-color-dark-good-minor: #00531e;
  --carpenter-core-color-dark-on-good-minor: #9af8a2;
  --carpenter-core-color-dark-beware: #dbc766;
  --carpenter-core-color-dark-beware-minor: #524600;
  --carpenter-core-color-dark-on-beware: #393000;
  --carpenter-core-color-dark-on-beware-minor: #f8e37f;
  --carpenter-core-color-dark-absent: #c9c4d0;
  --carpenter-core-color-dark-absent-minor: #48454e;
  --carpenter-core-color-dark-on-absent: #312f38;
  --carpenter-core-color-dark-on-absent-minor: #e2e1ec;
  --carpenter-core-color-dark-scrim: #ffffff99;
  --carpenter-core-color-dark-on-surface: #e4e1e6;
  --carpenter-core-color-dark-on-surface-minor: #c9c4d0;
  --carpenter-core-color-dark-surface: #1b1b1f;
  --carpenter-core-color-dark-surface-minor: #45464f;
  --carpenter-core-color-dark-inverse-primary: #5d36f1;
  --carpenter-core-color-dark-inverse-surface: #e5e1e6;
  --carpenter-core-color-dark-on-inverse-surface: #303033;
  --carpenter-core-color-dark-surface-tint: #c9bfff;
  --carpenter-ext-color-neutral-20: #303033;
  --carpenter-ext-color-neutral-10: #1b1b1e;
  --carpenter-ext-color-primary-0: #000000;
  --carpenter-ext-color-primary-30: #4400d9;
  --carpenter-ext-color-primary-40: #5d36f1;
  --carpenter-ext-color-primary-50: #775aff;
  --carpenter-ext-color-primary-60: #917eff;
  --carpenter-ext-color-neutral-70: #ADAAAF;
  --carpenter-ext-color-primary-80: #c9bfff;
  --carpenter-ext-color-primary-90: #e5deff;
  --carpenter-ext-color-primary-95: #f4eeff;
  --carpenter-ext-color-primary-99: #fffbff;
  --carpenter-ext-color-primary-100: #ffffff;
  --carpenter-ext-color-secondary-0: #000000;
  --carpenter-ext-color-secondary-10: #2f004c;
  --carpenter-ext-color-secondary-20: #4e0079;
  --carpenter-ext-color-secondary-30: #6e00aa;
  --carpenter-ext-color-secondary-40: #9100dd;
  --carpenter-ext-color-secondary-50: #af30ff;
  --carpenter-ext-color-secondary-60: #c166ff;
  --carpenter-ext-color-secondary-70: #d28fff;
  --carpenter-ext-color-secondary-80: #e3b5ff;
  --carpenter-ext-color-secondary-90: #f3daff;
  --carpenter-ext-color-secondary-95: #fbecff;
  --carpenter-ext-color-secondary-99: #fffbff;
  --carpenter-ext-color-secondary-100: #ffffff;
  --carpenter-ext-color-accent-0: #000000;
  --carpenter-ext-color-accent-10: #001e2b;
  --carpenter-ext-color-accent-20: #003547;
  --carpenter-ext-color-accent-30: #004d66;
  --carpenter-ext-color-accent-40: #006686;
  --carpenter-ext-color-accent-50: #0081a8;
  --carpenter-ext-color-accent-60: #329bc5;
  --carpenter-ext-color-accent-70: #55b6e1;
  --carpenter-ext-color-accent-80: #73d2fe;
  --carpenter-ext-color-accent-90: #c0e8ff;
  --carpenter-ext-color-accent-95: #e1f3ff;
  --carpenter-ext-color-accent-99: #fafcff;
  --carpenter-ext-color-accent-100: #ffffff;
  --carpenter-ext-color-bad-0: #000000;
  --carpenter-ext-color-bad-10: #410002;
  --carpenter-ext-color-bad-20: #690004;
  --carpenter-ext-color-bad-30: #930009;
  --carpenter-ext-color-bad-40: #ba1a1a;
  --carpenter-ext-color-bad-50: #de372f;
  --carpenter-ext-color-bad-60: #ff5449;
  --carpenter-ext-color-bad-70: #ff897d;
  --carpenter-ext-color-bad-80: #ffb4ab;
  --carpenter-ext-color-bad-90: #ffdad5;
  --carpenter-ext-color-bad-95: #ffedea;
  --carpenter-ext-color-bad-99: #fffbff;
  --carpenter-ext-color-bad-100: #ffffff;
  --carpenter-ext-color-good-0: #000000;
  --carpenter-ext-color-good-10: #002108;
  --carpenter-ext-color-good-20: #003912;
  --carpenter-ext-color-good-30: #00531e;
  --carpenter-ext-color-good-40: #006e2a;
  --carpenter-ext-color-good-50: #2b8840;
  --carpenter-ext-color-good-60: #48a357;
  --carpenter-ext-color-good-70: #63be70;
  --carpenter-ext-color-good-80: #7edb89;
  --carpenter-ext-color-good-90: #9af8a2;
  --carpenter-ext-color-good-95: #c6ffc6;
  --carpenter-ext-color-good-99: #f6fff1;
  --carpenter-ext-color-good-100: #ffffff;
  --carpenter-ext-color-beware-0: #000000;
  --carpenter-ext-color-beware-10: #211b00;
  --carpenter-ext-color-beware-20: #393000;
  --carpenter-ext-color-beware-30: #524600;
  --carpenter-ext-color-beware-40: #6d5e00;
  --carpenter-ext-color-beware-50: #87771e;
  --carpenter-ext-color-beware-60: #a29136;
  --carpenter-ext-color-beware-70: #beab4e;
  --carpenter-ext-color-beware-80: #dbc766;
  --carpenter-ext-color-beware-90: #f8e37e;
  --carpenter-ext-color-beware-99: #fffbff;
  --carpenter-ext-color-beware-100: #ffffff;
  --carpenter-ext-color-neutral-0: #000000;
  --carpenter-ext-color-neutral-30: #47464a;
  --carpenter-ext-color-neutral-40: #5f5e61;
  --carpenter-ext-color-neutral-50: #77767a;
  --carpenter-ext-color-neutral-60: #919094;
  --carpenter-ext-color-neutral-80: #c9c5ca;
  --carpenter-ext-color-neutral-90: #e5e1e6;
  --carpenter-ext-color-neutral-95: #f3f0f4;
  --carpenter-ext-color-neutral-99: #fefbff;
  --carpenter-ext-color-neutral-100: #ffffff;
  --carpenter-ext-color-neutralvariant-0: #000000;
  --carpenter-ext-color-neutralvariant-10: #1c1a22;
  --carpenter-ext-color-neutralvariant-20: #312f38;
  --carpenter-ext-color-neutralvariant-30: #48454e;
  --carpenter-ext-color-neutralvariant-40: #605d66;
  --carpenter-ext-color-neitralvariant-50: #79757f;
  --carpenter-ext-color-neutralvariant-60: #938f99;
  --carpenter-ext-color-neutralvariant-70: #aea9b4;
  --carpenter-ext-color-neutralvariant-80: #c9c4d0;
  --carpenter-ext-color-neutralvariant-95: #f4eefa;
  --carpenter-ext-color-neutralvariant-90: #e6e0ec;
  --carpenter-ext-color-neutralvariant-99: #fffbff;
  --carpenter-ext-color-neutralvariant-100: #ffffff;
  --carpenter-ext-color-primary-20: #2e009c;
}
